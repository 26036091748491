import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Text>
                    Ja disponible!
                    <br />
                    <br />
                    Tot el temari de policia local en format de Podcast!
                    <br />
                    <br />
                    En aquests àudios tindràs tots els temes esquematitzats, parlant dels punts clau
                    més importants i separats pels diferents temes que ens demanen les bases de la
                    convocatòria.
                    <br />
                    <br />
                    Tindràs:
                    <br />
                    <br />
                    -Accés als 17 podcast
                    <br />
                    <br />
                    -Més de 6 hores d'àudios perquè escoltis les vegades que vulguis i on vulguis
                    els àudios.
                    <br />
                    <br />
                    -Una narració propera i no monòtona.
                    <br />
                    <br />
                    -Música de fons perquè l'escolta sigui més amena i motivadora.
                    <br />
                    <br />
                    -Un super-resum al final de cada podcast per a un repàs ràpid de conceptes.I el
                    millor de tot és que això està pensat perquè aprofitis el teu temps!
                    <br />
                    <br />
                    -Els podcast no es poden descarregar.
                    <br />
                    <br />
                    Els podcast disponibles:
                    <br />
                    <br />
                    Podcast 0. Introducció
                    <br />
                    <br />
                    Podcast 1. La Constitució Espanyola de 1978
                    <br />
                    <br />
                    Podcast 2. L' Estatut d'autonomia de Catalunya de 2006
                    <br />
                    <br />
                    Podcast 3. Llei 16/1991 de 10 de juliol de policies locals
                    <br />
                    <br />
                    Podcast 4. Llei 2/1986 de 13 de març Forces i Cossos de Seguretat
                    <br />
                    <br />
                    Podcast 5. Llei 4/2003 de 7 d'abril, D'ordenació del sistema de Seguretat
                    pública de Catalunya
                    <br />
                    <br />
                    Podcast 6. Llei 8/1987 El municipi
                    <br />
                    <br />
                    Podcast 7. La Unió Europea
                    <br />
                    <br />
                    Podcast 8. Estatut bàsic de l'empleat públic
                    <br />
                    <br />
                    Podcast 9. Llei de Trànsit
                    <br />
                    <br />
                    Podcast 10. Llei 10/1995 de 23 de novembre de Codi penal
                    <br />
                    <br />
                    Podcast 11. VMP Vehicles de mobilitat personal
                    <br />
                    <br />
                    Podcast 12. Reglament d'armes
                    <br />
                    <br />
                    Podcast 13. Llei de protecció d'animals de Catalunya
                    <br />
                    <br />
                    Podcast 14. Llei orgànica 5/2000 de la responsabilitat dels menors
                    <br />
                    <br />
                    Podcast 15. Llei orgànica 1/2004, de 28 de desembre, de Mesures de Protecció
                    Integral contra la Violència de Gènere.
                    <br />
                    <br />
                    Podcast 16. Codi d'Ètica de Catalunya
                    <br />
                    <br />
                    Podcast 17.Llei 39/2015 del Procediment administratiu
                    <br />
                    <br />
                    Pensat perquè aprofitis el temps repassant el teu temari mentre entrenes o vas
                    al treball!
                    <br />
                    <br />
                    Pots posar-te els auriculars i estudiar/repassar el temari mentre vas al
                    treball, mentre entrenes les teves proves físiques o mentre estàs netejant a
                    casa!
                    <br />
                    <br />
                    Un pagament únic de 69.99 euros
                    <br />
                    <br />
                    Els podcast estarán disponibles 12 mesos desde la matriculació.
                    <br />
                    <br />
                    Comencem?
                </Text>
            </div>

            {/** ¿Cómo accedo? */}
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};

export default Product;
